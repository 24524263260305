<template>
  <div class="website-wrapper">
    <YzgHeader></YzgHeader>
    <div class="college-wrap" style="padding-top: 100px;">
<!--      模块1-->
      <section class="col-part1 col-brief">
        <div class="college-main">
          <h2 class="col-heading">云掌柜民宿学苑</h2>
          <p class="col-subtitle">——专注于打造专属于民宿人 “学习、分享、交流” 的成长平台。</p>
          <p class="col-text">· 旨在提高民宿营销管理水平 <br />· 增加民宿主之间的交流沟通 <br /> · 全面提升民宿人的认知和盈利能力</p>
          <div class="col-ewm">
            <div class="col-ewm-pic"><img src="../assets/pic/collegePic/col-wechat.png" alt=""></div>
            <p class="col-ewm-tip">扫码进入直播间<br/>学习最新课程<br/>了解行业动态</p>
          </div>
        </div>
      </section>
<!--      模块2-->
      <section class="col-part2 col-lecturer">
        <div class="college-main">
          <div class="col-header">
            <i class="col-route"></i>
            <i class="col-triangle"></i>
            <h3 class="col-title">云掌柜民宿学苑合作招募</h3>
            <p class="col-subtitle inn-subtitle">云掌柜民宿学苑面向个人、民宿、平台进行课程讲师招募与沙龙场地招募 如果你热爱民宿、喜欢分享、想要与同行一起交流进步<br />那么，快来吧~
            </p>
          </div>
          <div class="col-duty">
            <div class="col-duty-pic">
              <img src="../assets/pic/collegePic/col-duty.png" alt="">
            </div>
            <div class="col-duty-content">
              <h3 class="col-little-title">民宿学苑合伙人招募</h3>
              <p class="col-text">（针对个人/民宿）</p>
            </div>
          </div>
        </div>
      </section>
<!--      模块3-->
      <section class="col-part1 col-harvest">
        <div class="college-main">
          <h3 class="col-title">可享权益</h3>
          <ul class="clx">
            <li class="col-item">
              <i class="col-icon icon-dinner"></i>
              <h3 class="col-subhead">大咖私享会</h3>
              <p class="col-text">成为民宿学苑合伙人，参与大咖私享会，与行业大咖共同探讨发展趋势</p>
            </li>
            <li class="col-item">
              <i class="col-icon icon-certificate"></i>
              <h3 class="col-subhead">专业讲师聘书(讲师专属权益)</h3>
              <p class="col-text">可获“云掌柜民宿学苑专业讲师聘书”，聘书分为不同等级，附赠不同权益</p>
            </li>
            <li class="col-item">
              <i class="col-icon icon-member"></i>
              <h3 class="col-subhead">个人合伙人专属权益</h3>
              <p class="col-text">可获得民宿房券2张（价值≥1000元），凭房券免费入住民宿</p>
            </li>
          </ul>
          <ul class="clx">
            <li class="col-item">
              <i class="col-icon icon-exposure"></i>
              <h3 class="col-subhead">10万+公众号曝光</h3>
              <p class="col-text">免费享受通过公众号进行讲师个人品牌打造，或民宿推广曝光</p>
            </li>
            <li class="col-item">
              <i class="col-icon icon-course"></i>
              <h3 class="col-subhead">民宿合伙人专属权益</h3>
              <p class="col-text">可享受云掌柜季度高级会员，高效管理民宿</p>
            </li>
            <li class="col-item">
              <i class="col-icon icon-bonus"></i>
              <h3 class="col-subhead">课程奖金(讲师专属权益)</h3>
              <p class="col-text">付费课程，讲师可获得相应课时费</p>
            </li>
          </ul>
        </div>
      </section>
<!--      模块4-->
      <section class="col-part2 col-howto">
        <div class="college-main">
          <div class="col-recruit-box">
            <div class="col-recruit-left">
              <h3>课程讲师招募要求</h3>
              <ol>
                <li>可以独立制作课件PPT及录制音视频</li>
                <li>拥有成功运营管理民宿/酒店的案例</li>
                <li>至少3年及以上旅游行业从业经验</li>
                <li>至少已开发一门独立原创课程</li>
                <li>对云掌柜平台有强烈的认同感</li>
              </ol>
            </div>
            <div class="col-recruit-right">
              <h3>课程讲师报名方式</h3>
              <img src="../assets/pic/collegePic/baoming_code.png" alt="" style="width: 120px;margin:0 auto;display: block;">
            </div>
          </div>
          <div class="col-recruit-box mt60">
            <div class="col-recruit-left">
              <h3>沙龙场地招募要求</h3>
              <ol>
                <li>可提供容纳30人的活动场地</li>
                <li>合伙人具有一定的行业影响力</li>
              </ol>
            </div>
            <div class="col-recruit-right">
              <h3>沙龙场地报名方式</h3>
              <img src="../assets/pic/collegePic/salong_code.png" alt="" style="width: 120px;margin:0 auto;display: block;">
            </div>
          </div>
        </div>
      </section>
<!--      模块5-->
      <section class="col-part1 col-harvest">
        <div class="college-main">
          <h3 class="col-title" style="margin-bottom:41px;">民宿学苑合作方招募
            <br>
            （针对平台）
          </h3>
          <div class="col-recruit-box">
            <div class="col-recruit-left">
              <h3 style="text-align: left;">合作方式</h3>
              <ol>
                <li>以品牌合作形式进行联合宣传，发挥双方平台优势，获取更多流量</li>
                <li>课程免费上线云掌柜民宿学苑首页，并对平台及讲师进行包装宣传</li>
                <li>付费课程，平台可获得相应课时费</li>
                <li>成为民宿学苑合作方，受邀参与大咖私享会，共同探讨行业发展趋势</li>
              </ol>
              <span class="position_num">01</span>
            </div>
          </div>
          <div class="col-recruit-list">
            <div class="item">
              <div style="padding:40px 0 0 30px">
                <h3>讲师合作要求</h3>
                <ol style="margin-top: 48px;">
                  <li>
                    由平台讲师提供专业课程
                  </li>
                  <li>授权云掌柜对课程进行宣传与分销</li>
                </ol>
                <span class="position_num">02</span>
              </div>
            </div>
            <div class="item">
              <div style="padding:40px 0 0 30px">
                <h3>场地合作要求</h3>
                <ol style="margin-top: 40px;">
                  <li>
                    由平台方进行场地选择与布置，
                    <br />
                    <span style="font-size: 12px;margin-left: 24px;">云掌柜辅助进行</span>
                  </li>
                  <li>场地内有云掌柜logo露出</li>
                </ol>
                <span class="position_num">03</span>
              </div>
            </div>
            <div class="item">
              <div style="padding:40px 0 0 30px">
                <h3>平台合作方式</h3>
                <img src="../assets/pic/collegePic/pingtai_code.png" alt="" style="width: 120px;margin-top: 10px;">
                <span class="position_num">04</span>
              </div>
            </div>
          </div>
        </div>
      </section>
<!--      模块6-->
      <section class="col-part2 col-howto">
        <div class="college-main">
          <h3 class="col-title">往期沙龙展示</h3>
          <ul class="salon_ul_box">
            <li>
              <img src="../assets/pic/collegePic/salonBanner1.png" alt="">
              <p>黄山丨云掌柜X诗莉莉连锁精品酒店</p>
            </li>
            <li>
              <img src="../assets/pic/collegePic/salonBanner2.png" alt="">
              <p>大理丨云掌柜X古曼连锁酒店</p>
            </li>
            <li class="pt43">
              <img src="../assets/pic/collegePic/salonBanner3.png" alt="">
              <p>上海丨云掌柜上海总部</p>
            </li>
            <li class="pt43">
              <img src="../assets/pic/collegePic/salonBanner4.png" alt="">
              <p>杭州丨云掌柜X西湖茶墅民宿</p>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <YzgFooter></YzgFooter>
  </div>


</template>

<script>
import YzgHeader from "@/components/YzgHeader";
import YzgFooter from "@/components/YzgFooter";
export default {
  name: "college",
  components: {
    YzgHeader,
    YzgFooter,
  },
  methods: {
  },
  computed: {},
  props: {},
  watch: {},
  created () {
  },
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang='less' scoped>
/*@import url(); 引入公共css类*/
@import url("../assets/index.css");
@import url("../assets/media.css");
@import url("../assets/mediaCollege.css");
@media screen and (min-width: 768px){
  ol, ul, li {
    list-style: none;
  }
  .col-part1 {
    width: 100%;
    background-color: #fff;
    margin: 0 auto;
  }
  .col-part2 {
    width: 100%;
    background-color: #F7F7F7;
    margin: 0 auto;
  }
  .college-main {
    width: 910px;
    margin: 0 auto;
  }
  //模块一
  .col-brief .college-main {
    padding: 40px 0;
    background: url(../assets/pic/collegePic/col-brief.png) no-repeat right center;
  }
  .col-brief .col-heading {
    width: 336px;
    height: 67px;
    font-size: 48px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222;
    line-height: 67px;
    padding: 15px 0;
  }
  .col-brief .col-subtitle {
    width: 100%;
    height: 26px;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222;
    line-height: 26px;
    margin: 15px 0;
  }
  .col-brief .col-text {
    width: 198px;
    height: 72px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666;
    line-height: 24px;
  }
  .col-ewm {
    width: 231px;
    height: 132px;
    background: #FFF;
    box-shadow: 0 2px 30px 0 rgba(163, 163, 163, 0.21);
    margin: 80px 0 40px;
    padding: 8px;
  }
  .col-ewm-pic img {
    width: 116px;
    height: 116px;
    float: left;
    display: block;
  }
  .col-ewm-tip {
    width: 84px;
    height: 70px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666;
    line-height: 24px;
    float: left;
    margin: 23px 8px 23px 7px;
  }
  //模块二
  .col-lecturer {
    padding: 90px 0;
    background: #f7f7f7;
  }

  .col-header {
    position: relative;
    text-align: center;
  }

  .col-title {
    font-size: 36px;
    color: #222;
  }

  .col-lecturer .col-subtitle {
    font-size: 24px;
    color: #666;
    margin: 10px 0 0;
  }

  .col-route {
    position: absolute;
    left: 105px;
    top: 0;
    width: 76px;
    height: 43px;
    background: url(../assets/pic/collegePic/col-route.png) no-repeat center center;
  }

  .col-triangle {
    position: absolute;
    width: 58px;
    height: 52px;
    right: 72px;
    bottom: -30px;
    background: url(../assets/pic/collegePic/col-triangle.png) no-repeat center center;
  }
  .col-lecturer .inn-subtitle {
    width: 768px;
    height: 99px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #666;
    line-height: 33px;
    text-align: center;
    margin: 12px auto 0;
  }
  .col-duty {
    width: 100%;
    height: 200px;
    background: #fff;
    margin: 75px 0 0;
  }

  .col-duty-pic {
    float: left;
  }

  .col-duty-pic img {
    display: block;
    width: 100%;
  }

  .col-duty-content {
    float: left;
    padding: 45px 61px 0 56px;
  }

  .col-little-title {
    font-size: 24px;
    color: #222;
    margin: 0 0 20px;
  }

  .col-lecturer .col-text {
    font-size: 16px;
    line-height: 28px;
    color: #666;
  }
  //模块三
  .col-harvest {
    padding: 90px 0;
  }

  .col-harvest .col-title {
    text-align: center;
  }

  .col-harvest .col-item {
    float: left;
    padding: 60px 0 0 0;
    width: 33.33%;
    text-align: center;
  }

  .col-harvest .col-item .col-icon {
    display: block;
    margin: 0 auto;
    width: 72px;
    height: 72px;
  }
  .icon-dinner {
    background: url(../assets/pic/collegePic/col-dinner.png) no-repeat center center;
  }

  .icon-certificate {
    background: url(../assets/pic/collegePic/col-certificate.png) no-repeat center center;
  }

  .icon-member {
    background: url(../assets/pic/collegePic/col-member.png) no-repeat center center;
  }

  .icon-exposure {
    background: url(../assets/pic/collegePic/col-exposure.png) no-repeat center center;
  }

  .icon-course {
    background: url(../assets/pic/collegePic/col-course.png) no-repeat center center;
  }

  .icon-bonus {
    background: url(../assets/pic/collegePic/col-bonus.png) no-repeat center center;
  }
  .clx:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
    visibility: hidden;
    color: #333;
  }
  .col-harvest .col-item .col-subhead {
    width: 310px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2B2B2B;
    line-height: 33px;
    padding: 10px 0;
  }

  .col-harvest .col-item .col-text {
    width: 280px;
    height: 56px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 28px;
    color: #666;
    margin: 0 auto;
  }
  //模块四
  .col-howto {
    padding: 90px 0;
    background: #f7f7f7;
  }

  .col-howto .col-title {
    text-align: center;
  }

  .col-howto .col-item {
    float: left;
    width: 220px;
    height: 233px;
    background: #fff;
    margin: 0 35px 0 0;
    padding: 47px 30px 0 30px;
    box-shadow: 0px 2px 30px 0px rgba(163, 163, 163, 0.21);
  }

  .col-howto .col-item:nth-child(3n) {
    margin: 0;
  }

  .col-howto .col-item .col-subtitle {
    text-align: center;
  }

  .col-howto ul {
    margin: 60px 0 0;
  }

  .col-howto .col-item li {
    font-size: 12px;
    line-height: 24px;
    color: #717171;
    position: relative;
  }

  .col-howto .col-item ol {
    margin: 32px 0 0;
  }

  .col-howto .col-item li::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../assets/pic/collegePic/col-right.png) no-repeat center center;
    vertical-align: -3px;
    margin: 0 5px 0 0;
  }

  .col-howto .col-text {
    font-size: 12px;
    color: #666;
    text-align: center;
  }

  .col-signup-pic {
    margin: 20px auto 0;
    width: 144px;
  }

  .college-entrance {
    float: right;
    /* margin: 35px 0 0 30px; */
  }

  .college-entrance a {
    display: inline-block;
    /* width: 110px; */
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 16px;
    font-size: 14px;
    color: #666;
  }

  .college-entrance a.click {
    /* background: #f5f5f5; */
    color: black;
    font-size: 14px;
    line-height: 100px;
    height: 100px;
  }


  .copy a {
    color: #fff;
  }

  .col-heading-text {
    width: 198px;
    height: 72px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
  }

  .col-recruit-box {
    width: 100%;
    height: 280px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 6px 30px 0px rgba(195, 195, 195, 0.21);
    position: relative;
  }

  .col-recruit-box h3 {
    height: 32px;
    font-size: 24px;
    font-weight: 500;
    color: #222222;
    line-height: 32px;
    text-align: center;
    margin-bottom: 24px;
  }

  .col-recruit-box .col-recruit-left {
    margin-left: 104px;
  }

  .col-recruit-box .col-recruit-right {
    margin-right: 104px;
  }

  .col-recruit-box .col-recruit-left ol li {
    font-size: 12px;
    font-weight: 400;
    color: #717171;
    /* margin-bottom: 8px; */
    line-height: 24px;
  }

  ol li::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../assets/pic/collegePic/col-right.png) no-repeat center center;
    vertical-align: -3px;
    margin: 0 5px 0 0;
  }

  ol li {
    font-size: 12px;
    font-weight: 400;
    color: #717171;
    line-height: 24px;
  }

  .mt60 {
    margin-top: 60px;
  }

  .position_num {
    position: absolute;
    top: 0;
    right: 16px;
    width: 91px;
    height: 126px;
    font-size: 90px;
    font-weight: 400;
    color: #F7F8F7;
    line-height: 126px;

  }

  .col-recruit-list {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
  }

  .col-recruit-list .item {
    width: 280px;
    height: 220px;
    background: #FFFFFF;
    box-shadow: 0px 6px 30px 0px rgba(195, 195, 195, 0.21);
    position: relative;
  }

  .col-recruit-list .item h3 {
    height: 32px;
    font-size: 24px;
    font-weight: 500;
    color: #222222;
    line-height: 32px;
  }

  .salon_ul_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .salon_ul_box li p {
    margin-top: 21px;
    height: 33px;
    font-size: 24px;
    font-weight: 500;
    color: #2B2B2B;
    line-height: 33px;
    text-align: center;
    margin-bottom: 40px;
  }

}
</style>
